<template>
  <v-container
    fluid
    tag="section"
  >
    <!--  -->
    <v-row justify="center">
      <!--  -->
      <v-col
        cols="12"
        md="8"
      >
        <!--  -->
        <base-material-card>
          <!--  -->
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Edit Your news
            </div>
          </template>
          <!--  -->
          <v-form>
            <!--  -->
            <v-container class="py-0">
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="news.data.id"
                    label="ID"
                    disabled
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    v-model="news.data.title"
                    filled
                    label="Tittle"
                    clearable
                  >
                    <!--  -->
                  </v-text-field>
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-select
                    v-model="news.data.status"
                    :items="status_items"
                    filled
                    label="Pilih Status"
                  >
                    <!--  -->
                  </v-select>
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-textarea
                    v-model="news.data.post"
                    label="Post Artikel"
                    filled
                    clearable
                    :rules="rules"
                  >
                    <!--  -->
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-img
                    v-if="news.data.image.image.small"
                    :src="news.data.image.image.small"
                    height="200"
                    width="200"
                    aspect-ratio="1.7"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <v-file-input
                    v-model="file1"
                    :state="Boolean(file1)"
                    :loading="upload_state"
                    :disabled="disable_state"
                    counter
                    show-size
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    placeholder="Edit Gambar Thumbnail"
                    @change="uploadImage"
                  />
                </v-col>
              </v-row>
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="news.data.created_at | moment('D MMM YYYY')"
                    label="Di Buat"
                    disabled
                  />
                </v-col>
              </v-row>
              <!--  -->
              <!--  -->

              <!--  -->
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="getUpdate"
                  >
                    SUNTING
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    data () {
      return {
        news: {
          meta: {},
          data: {
            title: '',
            post: '',
            status: '',
            prefix: 'publish',
            status_items: ['publish', 'draft', 'review'],
            image: {
              image: {},
            },
          },
        },
        polling: {
          data: [],
        },
        status_items: ['publish', 'draft', 'review'],
        isEditing: false,
        rules: [
          value =>
            value.length > 3 ||
            'Form Harus Di isi dan harus Lebih dari 3 Karakter ',
        ],
        upload_state: false,
        disable_state: false,
        file1: null,
        image_id: 4,
        thumbnail_id: 4,
      }
    },
    mounted () {
      this.getnews()
    // this.getPolling()
    },
    methods: {
      upload (event) {
        this.upload_state = true
        console.log(event.target.files[0])
      },
      uploadImage (event) {
        if (this.file1 === null) {
          this.upload_state = false
          this.disable_state = false
          return 1
        }
        const URL = '/v1/media'
        this.upload_state = true
        const data = new FormData()
        data.append(
          'path',
          'image/news/community/' + this.$store.state.user.community_id,
        )
        data.append('file', this.file1)
        const config = {
          header: {
            'Content-Type': 'image/png',
          },
        }

        axios.post(URL, data, config).then(response => {
          if (response.data.meta.status) {
            this.upload_state = false
            this.disable_state = false
            this.image_id = response.data.data.id
            this.$toast.success('Berhasil Menunggah Gambar', {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          } else {
            this.upload_state = false
            this.disable_state = true
            this.$toast.error('Maksimal ukuran gambar 1MB', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          }
        })
      },
      // setPolling (data) {
      //   this.polling = data
      // },
      // getPolling () {
      //   axios
      //     .get('/v1/polling?sort=-id&entities=user,community,options')
      //     .then(response => {
      //       this.setPolling(response.data)
      //     })
      // },
      getUpdate () {
        this.disable_state = true
        axios
          .patch('/v1/post/news/' + this.$route.params.id, {
            title: this.news.data.title,
            post: this.news.data.post,
            status: this.news.data.status,
            thumbnail_id: this.image_id,
            image_id: this.image_id,
          // polling_id: this.news.data.polling_id,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/news' })
              this.$toast.success('Berhasil Di Sunting', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              this.$toast.error('error', {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
      setnews (data) {
        this.news = data
      },
      getnews () {
        axios
          .get('/v1/post/news/' + this.$route.params.id)
          .then(response => {
            this.setnews(response.data)
            this.image_id = response.data.data.image_id
          })
          .catch(e => {
            console.error(e)
          })
      },
    },
  }
</script>

<style></style>
